*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.tw-pointer-events-none {
    pointer-events: none
}
.tw-fixed {
    position: fixed
}
.tw-relative {
    position: relative
}
.tw-z-10 {
    z-index: 10
}
.tw-order-1 {
    order: 1
}
.tw-order-2 {
    order: 2
}
.\!tw-m-0 {
    margin: 0px !important
}
.tw-m-0 {
    margin: 0px
}
.tw-m-4 {
    margin: 1rem
}
.\!tw-mx-auto {
    margin-left: auto !important;
    margin-right: auto !important
}
.\!tw-my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important
}
.tw-mx-0 {
    margin-left: 0px;
    margin-right: 0px
}
.tw-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
.tw-my-0 {
    margin-top: 0px;
    margin-bottom: 0px
}
.tw-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem
}
.tw-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
}
.tw-my-auto {
    margin-top: auto;
    margin-bottom: auto
}
.\!-tw-mt-2 {
    margin-top: -0.5rem !important
}
.\!-tw-mt-80 {
    margin-top: -20rem !important
}
.\!tw-mb-0 {
    margin-bottom: 0px !important
}
.\!tw-mt-2 {
    margin-top: 0.5rem !important
}
.\!tw-mt-20 {
    margin-top: 5rem !important
}
.\!tw-mt-3 {
    margin-top: 0.75rem !important
}
.\!tw-mt-auto {
    margin-top: auto !important
}
.-tw-mb-20 {
    margin-bottom: -5rem
}
.-tw-mb-3 {
    margin-bottom: -0.75rem
}
.-tw-ml-2 {
    margin-left: -0.5rem
}
.-tw-ml-3 {
    margin-left: -0.75rem
}
.-tw-ml-5 {
    margin-left: -1.25rem
}
.-tw-mr-2 {
    margin-right: -0.5rem
}
.-tw-mt-1 {
    margin-top: -0.25rem
}
.-tw-mt-1\.5 {
    margin-top: -0.375rem
}
.-tw-mt-2 {
    margin-top: -0.5rem
}
.-tw-mt-3 {
    margin-top: -0.75rem
}
.-tw-mt-4 {
    margin-top: -1rem
}
.-tw-mt-5 {
    margin-top: -1.25rem
}
.tw-mb-0 {
    margin-bottom: 0px
}
.tw-mb-2 {
    margin-bottom: 0.5rem
}
.tw-mb-4 {
    margin-bottom: 1rem
}
.tw-ml-1 {
    margin-left: 0.25rem
}
.tw-ml-2 {
    margin-left: 0.5rem
}
.tw-ml-4 {
    margin-left: 1rem
}
.tw-ml-auto {
    margin-left: auto
}
.tw-mr-1 {
    margin-right: 0.25rem
}
.tw-mr-10 {
    margin-right: 2.5rem
}
.tw-mr-2 {
    margin-right: 0.5rem
}
.tw-mr-3 {
    margin-right: 0.75rem
}
.tw-mr-auto {
    margin-right: auto
}
.tw-mt-0 {
    margin-top: 0px
}
.tw-mt-1 {
    margin-top: 0.25rem
}
.tw-mt-10 {
    margin-top: 2.5rem
}
.tw-mt-2 {
    margin-top: 0.5rem
}
.tw-mt-20 {
    margin-top: 5rem
}
.tw-mt-3 {
    margin-top: 0.75rem
}
.tw-mt-5 {
    margin-top: 1.25rem
}
.tw-mt-7 {
    margin-top: 1.75rem
}
.tw-inline-block {
    display: inline-block
}
.tw-flex {
    display: flex
}
.\!tw-h-full {
    height: 100% !important
}
.tw-h-0 {
    height: 0px
}
.tw-h-10 {
    height: 2.5rem
}
.tw-h-12 {
    height: 3rem
}
.tw-h-14 {
    height: 3.5rem
}
.tw-h-24 {
    height: 6rem
}
.tw-h-32 {
    height: 8rem
}
.tw-h-6 {
    height: 1.5rem
}
.tw-h-9 {
    height: 2.25rem
}
.tw-h-full {
    height: 100%
}
.tw-h-screen {
    height: 100vh
}
.tw-max-h-60 {
    max-height: 15rem
}
.tw-max-h-\[500px\] {
    max-height: 500px
}
.tw-min-h-32 {
    min-height: 8rem
}
.tw-min-h-40 {
    min-height: 10rem
}
.tw-min-h-52 {
    min-height: 13rem
}
.tw-min-h-\[288px\] {
    min-height: 288px
}
.\!tw-w-full {
    width: 100% !important
}
.tw-w-10 {
    width: 2.5rem
}
.tw-w-14 {
    width: 3.5rem
}
.tw-w-24 {
    width: 6rem
}
.tw-w-28 {
    width: 7rem
}
.tw-w-5 {
    width: 1.25rem
}
.tw-w-56 {
    width: 14rem
}
.tw-w-72 {
    width: 18rem
}
.tw-w-\[100\%\] {
    width: 100%
}
.tw-w-\[320px\] {
    width: 320px
}
.tw-w-full {
    width: 100%
}
.tw-w-screen {
    width: 100vw
}
.tw-max-w-20 {
    max-width: 5rem
}
.tw-max-w-96 {
    max-width: 24rem
}
.tw-max-w-\[600px\] {
    max-width: 600px
}
.tw-max-w-md {
    max-width: 28rem
}
.tw-flex-1 {
    flex: 1 1 0%
}
.tw-table-auto {
    table-layout: auto
}
.tw-transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@keyframes tw-bounce {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8,0,1,1)
    }
    50% {
        transform: none;
        animation-timing-function: cubic-bezier(0,0,0.2,1)
    }
}
.tw-animate-\[bounce_1s_ease-in-out_2\.5\] {
    animation: tw-bounce 1s ease-in-out 2.5
}
@keyframes tw-slide {
    0% {
        transform: translate(-100%)
    }
    100% {
        transform: translate(0px)
    }
}
.tw-animate-\[slide_1s_ease-out\] {
    animation: tw-slide 1s ease-out
}
@keyframes tw-slideinRight {
    0% {
        transform: translate(100%)
    }
    100% {
        transform: translate(0px)
    }
}
.tw-animate-\[slideinRight_1s_ease-out\] {
    animation: tw-slideinRight 1s ease-out
}
@keyframes tw-wiggle {
    0%, 100% {
        transform: rotate(-3deg)
    }
    50% {
        transform: rotate(3deg)
    }
}
.tw-animate-\[wiggle_3s_linear_infinite\] {
    animation: tw-wiggle 3s linear infinite
}
.tw-cursor-pointer {
    cursor: pointer
}
.tw-list-inside {
    list-style-position: inside
}
.tw-list-disc {
    list-style-type: disc
}
.tw-flex-col {
    flex-direction: column
}
.tw-flex-wrap {
    flex-wrap: wrap
}
.tw-items-end {
    align-items: flex-end
}
.tw-items-center {
    align-items: center
}
.tw-justify-end {
    justify-content: flex-end
}
.tw-justify-center {
    justify-content: center
}
.tw-gap-1 {
    gap: 0.25rem
}
.tw-gap-2 {
    gap: 0.5rem
}
.tw-gap-3 {
    gap: 0.75rem
}
.tw-gap-4 {
    gap: 1rem
}
.tw-gap-5 {
    gap: 1.25rem
}
.tw-gap-x-3 {
    column-gap: 0.75rem
}
.tw-overflow-auto {
    overflow: auto
}
.tw-overflow-hidden {
    overflow: hidden
}
.tw-overflow-x-auto {
    overflow-x: auto
}
.tw-text-ellipsis {
    text-overflow: ellipsis
}
.\!tw-whitespace-normal {
    white-space: normal !important
}
.tw-whitespace-normal {
    white-space: normal
}
.tw-whitespace-nowrap {
    white-space: nowrap
}
.\!tw-rounded-none {
    border-radius: 0px !important
}
.tw-rounded-full {
    border-radius: 9999px
}
.tw-rounded-lg {
    border-radius: 0.5rem
}
.tw-rounded-xl {
    border-radius: 0.75rem
}
.tw-border {
    border-width: 1px
}
.tw-border-2 {
    border-width: 2px
}
.tw-border-4 {
    border-width: 4px
}
.tw-border-\[3px\] {
    border-width: 3px
}
.tw-border-t {
    border-top-width: 1px
}
.tw-border-solid {
    border-style: solid
}
.\!tw-border-none {
    border-style: none !important
}
.\!tw-border-slate-200 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important
}
.\!tw-border-yellow-400 {
    --tw-border-opacity: 1 !important;
    border-color: rgb(250 204 21 / var(--tw-border-opacity)) !important
}
.tw-border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity))
}
.tw-border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139 / var(--tw-border-opacity))
}
.tw-border-teal-300 {
    --tw-border-opacity: 1;
    border-color: rgb(94 234 212 / var(--tw-border-opacity))
}
.tw-border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgb(250 204 21 / var(--tw-border-opacity))
}
.\!tw-bg-transparent {
    background-color: transparent !important
}
.\!tw-bg-yellow-600\/30 {
    background-color: rgb(202 138 4 / 0.3) !important
}
.tw-bg-\[\#DDE8F3\]\/20 {
    background-color: rgb(221 232 243 / 0.2)
}
.tw-bg-gray-600\/30 {
    background-color: rgb(75 85 99 / 0.3)
}
.tw-bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 252 231 / var(--tw-bg-opacity))
}
.tw-bg-orange-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 237 213 / var(--tw-bg-opacity))
}
.tw-bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}
.tw-bg-theme-color-1 {
    background-color: var(--theme-color-1)
}
.tw-bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops))
}
.tw-stroke-2 {
    stroke-width: 2
}
.\!tw-p-0 {
    padding: 0px !important
}
.\!tw-p-4 {
    padding: 1rem !important
}
.tw-p-0 {
    padding: 0px
}
.tw-p-5 {
    padding: 1.25rem
}
.tw-p-6 {
    padding: 1.5rem
}
.tw-p-8 {
    padding: 2rem
}
.\!tw-px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important
}
.\!tw-py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important
}
.tw-px-0 {
    padding-left: 0px;
    padding-right: 0px
}
.tw-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.tw-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem
}
.tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
.tw-py-0 {
    padding-top: 0px;
    padding-bottom: 0px
}
.tw-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem
}
.tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.tw-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
}
.\!tw-pb-2 {
    padding-bottom: 0.5rem !important
}
.\!tw-pl-1 {
    padding-left: 0.25rem !important
}
.tw-pb-0 {
    padding-bottom: 0px
}
.tw-pb-3 {
    padding-bottom: 0.75rem
}
.tw-pl-1 {
    padding-left: 0.25rem
}
.tw-pl-1\.5 {
    padding-left: 0.375rem
}
.tw-pl-40 {
    padding-left: 10rem
}
.tw-pr-1 {
    padding-right: 0.25rem
}
.tw-pt-0 {
    padding-top: 0px
}
.tw-pt-2 {
    padding-top: 0.5rem
}
.tw-pt-4 {
    padding-top: 1rem
}
.tw-text-left {
    text-align: left
}
.tw-text-center {
    text-align: center
}
.tw-text-right {
    text-align: right
}
.tw-align-text-top {
    vertical-align: text-top
}
.\!tw-text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important
}
.\!tw-text-\[24px\] {
    font-size: 24px !important
}
.tw-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem
}
.tw-text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem
}
.tw-text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem
}
.tw-text-\[10px\] {
    font-size: 10px
}
.tw-text-\[11px\] {
    font-size: 11px
}
.tw-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.tw-text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.tw-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.tw-text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.\!tw-font-bold {
    font-weight: 700 !important
}
.\!tw-font-semibold {
    font-weight: 600 !important
}
.tw-font-bold {
    font-weight: 700
}
.tw-font-medium {
    font-weight: 500
}
.tw-font-normal {
    font-weight: 400
}
.tw-font-semibold {
    font-weight: 600
}
.tw-uppercase {
    text-transform: uppercase
}
.\!tw-capitalize {
    text-transform: capitalize !important
}
.tw-capitalize {
    text-transform: capitalize
}
.tw-italic {
    font-style: italic
}
.tw-leading-none {
    line-height: 1
}
.\!tw-text-slate-200 {
    --tw-text-opacity: 1 !important;
    color: rgb(226 232 240 / var(--tw-text-opacity)) !important
}
.\!tw-text-slate-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(148 163 184 / var(--tw-text-opacity)) !important
}
.\!tw-text-white {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}
.\!tw-text-yellow-400 {
    --tw-text-opacity: 1 !important;
    color: rgb(250 204 21 / var(--tw-text-opacity)) !important
}
.tw-text-\[\#EDEADE\] {
    --tw-text-opacity: 1;
    color: rgb(237 234 222 / var(--tw-text-opacity))
}
.tw-text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}
.tw-text-gray-50 {
    --tw-text-opacity: 1;
    color: rgb(249 250 251 / var(--tw-text-opacity))
}
.tw-text-green-300 {
    --tw-text-opacity: 1;
    color: rgb(134 239 172 / var(--tw-text-opacity))
}
.tw-text-green-400 {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity))
}
.tw-text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity))
}
.tw-text-green-600 {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity))
}
.tw-text-green-700 {
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity))
}
.tw-text-orange-200 {
    --tw-text-opacity: 1;
    color: rgb(254 215 170 / var(--tw-text-opacity))
}
.tw-text-orange-600 {
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity))
}
.tw-text-orange-700 {
    --tw-text-opacity: 1;
    color: rgb(194 65 12 / var(--tw-text-opacity))
}
.tw-text-primary-color {
    color: var(--primary-color)
}
.tw-text-purple-600 {
    --tw-text-opacity: 1;
    color: rgb(147 51 234 / var(--tw-text-opacity))
}
.tw-text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity))
}
.tw-text-red-900 {
    --tw-text-opacity: 1;
    color: rgb(127 29 29 / var(--tw-text-opacity))
}
.tw-text-slate-100 {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity))
}
.tw-text-slate-500 {
    --tw-text-opacity: 1;
    color: rgb(100 116 139 / var(--tw-text-opacity))
}
.tw-text-slate-600 {
    --tw-text-opacity: 1;
    color: rgb(71 85 105 / var(--tw-text-opacity))
}
.tw-text-theme-color-1 {
    color: var(--theme-color-1)
}
.tw-text-theme-color-2 {
    color: var(--theme-color-2)
}
.tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.tw-text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgb(253 224 71 / var(--tw-text-opacity))
}
.tw-shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
.tw-duration-500 {
    transition-duration: 500ms
}

/* .headroom--pinned a {
  color: blue !important; */
/* } */
.hover\:tw-shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.hover\:tw-backdrop-grayscale-0:hover {
    --tw-backdrop-grayscale: grayscale(0);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
            backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}
.tw-group:hover .group-hover\:tw-scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.tw-group:hover .group-hover\:tw-opacity-100 {
    opacity: 1
}
.tw-group:hover .group-hover\:tw-shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
@media (min-width: 640px) {
    .sm\:tw-mx-5 {
        margin-left: 1.25rem;
        margin-right: 1.25rem
    }
    .sm\:tw-mx-auto {
        margin-left: auto;
        margin-right: auto
    }
    .sm\:tw-w-auto {
        width: auto
    }
    .sm\:tw-flex-row {
        flex-direction: row
    }
    .sm\:tw-bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity))
    }
}
@media (min-width: 768px) {
    .md\:tw-order-1 {
        order: 1
    }
    .md\:tw-order-2 {
        order: 2
    }
    .md\:tw-mt-0 {
        margin-top: 0px
    }
    .md\:tw-flex-row {
        flex-direction: row
    }
}
@media (min-width: 1024px) {
    .lg\:tw-ml-auto {
        margin-left: auto
    }
    .lg\:tw-w-\[30\%\] {
        width: 30%
    }
    .lg\:tw-w-\[70\%\] {
        width: 70%
    }
    .lg\:tw-flex-row {
        flex-direction: row
    }
}
@media (min-width: 1280px) {
    .xl\:tw-text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem
    }
    .xl\:tw-text-6xl {
        font-size: 3.75rem;
        line-height: 1
    }
}
